<template>
  <div class="login-box flex">
    <div :class=" loginError ? 'login-info-error__box': 'login-info__box'">
      <h1 class="info-title">
        欢迎登录心安长久CIED随访系统
      </h1>
      <!-- <div class="info-subtitle">请使用手机账号登录~</div> -->
      <el-form class="info-form" ref="form" :model="loginForm">
        <el-form-item>
          <el-input v-model="loginForm.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入8~20位字母、数字或特殊字符的组合" type="password" v-model="loginForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item>
        <el-row type="flex" justify="space-around">
          <el-col :span="16">
            <el-input class="form-ver-code-ipt" placeholder="请输入验证码" v-model="loginForm.verifyCode" oninput="value=value.replace(/[^\d.]/g,'')" :maxlength="4"></el-input>
          </el-col>
          <el-col :span="8">
            <el-button class="form-ver-code-btn" :disabled="disabledVerCodeBtn" @click="handleVerificationCode(loginForm.username)">{{countDownText}}</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <span v-if="passwordErr" style="color: #d52e2e">{{errorPrompt}}</span>
      </el-form>
      <el-button class="login-btn" type="primary" :disabled="disabledLogin"  @click="doLogin">登 录</el-button>
    </div>
  </div>
</template>
<script>
import { login, getDoctorInfo, sendSMS, getUserAuthList } from '@/service/module/common'
import md5 from 'js-md5'
export default {
  data () {
    return {
      loginForm: {
        username: '',
        password: '',
        verifyCode: ''
      },
      passwordErr: false,
      disabledLogin: false,
      errorPrompt: '',

      countDownText: '获取验证码',
      disabledVerCodeBtn: false,
      loginError: false
    }
  },
  methods: {
    doLogin () {
      this._send('$click', '登录点击', '登录按钮 发生点击行为触发', {
        url: window.location.origin,
        username: this.loginForm.username
      })
      const params = {
        username: this.loginForm.username,
        password: this.loginForm.password ? md5(this.loginForm.password) : '',
        verifyCode: this.loginForm.verifyCode
      }
      var pattern = /(?!.*\s)(?!^[0-9]+$)(?!^[^A-Za-z0-9]+$)(?!^[^A-Za-z.*\s]+$)(?!^[^0-9.*\s]+$)^.{8,20}$/
      if (!this.loginForm.username) {
        this.passwordErr = true
        this.errorPrompt = '请输入账号'
        this.loginError = true
      } else if (!this.loginForm.password) {
        this.passwordErr = true
        this.errorPrompt = '请输入密码'
        this.loginError = true
      } else if (!pattern.test(this.loginForm.password)) {
        this.passwordErr = true
        this.errorPrompt = '密码有误，请重新输入'
        this.loginError = true
      } else if (!this.loginForm.verifyCode) {
        this.passwordErr = true
        this.errorPrompt = '请输入验证码'
        this.loginError = true
      } else if (this.loginForm.verifyCode.length !== 4) {
        this.errorPrompt = '请输入正确的验证码'
      } else {
        login(params).then(res => {
          if (res && res.code === 200) {
            this.disabledLogin = false
            this.passwordErr = false
            const token = res.data
            sessionStorage.setItem('Authorization', token)
            this.loginError = false
            this.getDoctor()
            this.getUserAuthList()
          } else {
            this.errorPrompt = res.message
            this.passwordErr = true
            this.disabledLogin = false
            this.loginError = true
          }
        }).catch(() => {
          this.$message.error('网络错误')
          this.disabledLogin = false
        })
      }
    },
    async getDoctor () {
      try {
        const res = await getDoctorInfo()
        sessionStorage.setItem('user', JSON.stringify(res.data))
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    async getUserAuthList () {
      try {
        const res = await getUserAuthList()
        sessionStorage.setItem('auth', JSON.stringify(res.data))
        await this.$router.push({
          path: '/'
        })
      } catch (e) {
        this.$message.error(e.message)
      }
    },
    // enterLogin () {
    //   this.doLogin()
    // },
    handleVerificationCode (name) {
      this.disabledVerCodeBtn = true
      sendSMS({
        userName: name
      }).then(res => {
        if (res.code === 200) {
          let val = 60
          const timer = setInterval(() => {
            if (val === 0) {
              this.countDownText = '获取验证码'
              this.disabledVerCodeBtn = false
              clearTimeout(timer)
            } else {
              val--
              this.countDownText = val + 's'
            }
          }, 1000)
        } else {
          this.disabledVerCodeBtn = false
          this.errorPrompt = res.message
          this.passwordErr = true
          this.disabledLogin = false
          this.loginError = true
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "src/style/common.scss";
  .login-box {
    overflow: hidden;
    @include domSize(100%, 100%);
    align-items: center;
    justify-content: flex-end;
    position: relative;
    background: url("https://f-u-system.oss-cn-beijing.aliyuncs.com/new.jpg") no-repeat;
    background-size: cover;
    .login-info__box,.login-info-error__box {
      @include domSize(397px, 368px);
      @include bgColor(#fff);
      margin-right: 91px;
      border-radius: 10px;
      padding: 50px 24px 0;
      .info-title {
        @include fs(#000, 24px, 700);
        line-height: 30.32px;
        margin-bottom: 34px;
      }
      .info-subtitle {
        @include fs(#A3b2c2, 14px, 400);
        line-height: 16.32px;
        margin-bottom: 12px;
      }
      .info-form {
        margin-bottom: 24px;
      }
      .login-btn {
        width: 100%;
        height: 40px;
        margin: 0 auto;
        @include fs(#fff, 14px, 700);
      }
    }
    .login-info-error__box {
      height: 400px;
    }
    .el-form-item {
      margin-bottom: 16px !important;
    }
    .form-ver-code-ipt {
      width: 223px;
    }
    .form-ver-code-btn {
      width: 100%;
    }
  }
</style>
